import "./BlogPage.scss";
import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";

const blogsData = {
  2024: [
    { title: "Where to Start?", link: "entries/where-to-start" },
    { title: "Another Blog Post", link: "entries/another-post" },
  ],
};

export default function BlogPage() {
  return (
    <div className="blog-page">
      <Header />
      <main className="blog-page__main">
        <div className="blog-page__container blog-page__container-top">
          <div className="blog-page__container blog-page__container--title">
            <h1 className="blog-page__title">1 - Minute Blogs</h1>
            <hr className="blog-page__underline" />
          </div>
          <button className="blog-page__button">Follow My Blog</button>
        </div>
        {Object.keys(blogsData).map((date) => (
          <div key={date} className="blog-page__container">
            <div className="blog-page__container-left">
              <p className="blog-page__container-time">{date}</p>
              <div className="blog-page__container-line" />
            </div>
            <div className="blog-page__container-right">
              {blogsData[date].map((blog, index) => (
                <Link
                  key={index}
                  to={blog.link}
                  className="blog-page__container-link"
                >
                  {blog.title}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </main>
    </div>
  );
}
