import "./ProjectSection.scss";
import React, { useState } from "react";
import EmberEarth from "../../assets/images/E&EPhoneMockup.png";
import RightArrow from "../../assets/icons/ArrowRightConer.svg";
import Swooshmockup from "../../assets/images/Swooshmockup.png";
import BandSite from "../../assets/images/BandSite.png";
import LevelUpCodeMockup from "../../assets/images/LevelUpCodeMockup.png";
import PricingPage from "../../assets/images/PricingPage.png";

const Projects = [
  {
    title: "Ember+Earth ",
    img: EmberEarth,
    description:
      "I built Ember+Earth, a natural products e-commerce site with a fall-inspired aesthetic. The site features a product catalog, responsive design, highlighting my ability to create functional, visually appealing web apps.",
    href: "https://emberandearth-d3889.web.app/home",
  },
  {
    title: "Swoosh ",
    img: Swooshmockup,
    description:
      "Created a web app for pick up games / social media, utilizing React.js for the front end, Firebase for real-time database management, and Sass for custom styling. Designed the user interface and experience using Figma to deliver an intuitive and visually appealing platform.",
    href: "https://swoosh-miami.web.app",
  },
  {
    title: "Level-Up Code ",
    img: LevelUpCodeMockup,
    description:
      "Created a web app for teaching coding, utilizing React.js for the front end, Firebase for real-time database management, and Sass for custom styling. Designed the user interface and experience using Photoshop, Figma, Canva, and Illustrator to deliver an intuitive and visually appealing platform.",
    href: "https://levelup-code.web.app/",
  },
  {
    title: "Bandsite",
    img: BandSite,
    description:
      "A two-page biography website created as part of a bootcamp project, based on a Figma design. The project demonstrates skills in JavaScript, Sass, CSS, and HTML, delivering a fully responsive and interactive experience.",
    href: "https://anthonyquispe.github.io/Bandsite/",
  },
  {
    title: "Pricing Plan - BootStrap",
    img: PricingPage,
    description: "Created a bootstrap project of a pricing page.",
    href: "https://anthonyquispe.github.io/PricingPage-Bootstrap/",
  },
  // {
  //   title: "New Project 3",
  //   img: LevelUpCodeMockup,
  //   description: "Description for New Project 3.",
  //   href: "https://example.com",
  // },
];

export default function ProjectSection() {
  const [currentPage, setCurrentPage] = useState(0); // Manage current page

  const projectsPerPage = 3; // Show 3 projects per page
  const totalPages = Math.ceil(Projects.length / projectsPerPage); // Total number of pages

  // Calculate projects to display for the current page
  const currentProjects = Projects.slice(
    currentPage * projectsPerPage,
    (currentPage + 1) * projectsPerPage
  );

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <section className="project-section" id="projects">
      <div className="project__containers">
        <div className="project__container-top">
          <h2 className="project-section__title">Projects</h2>
          <hr className="project-section__hr" />
        </div>
        <div className="project__container-bottom">
          {currentProjects.map((project, index) => (
            <div key={index} className="project__container">
              <img
                className="project__img"
                src={project.img}
                alt={project.title}
              />
              <a className="project__text-container" href={project.href}>
                <h3 className="project__title">{project.title}</h3>
                <p className="project__description">{project.description}</p>
                <img
                  className="project__link-arrow"
                  src={RightArrow}
                  alt="project right arrow link"
                />
              </a>
            </div>
          ))}
        </div>
        <div className="project-section__pagination">
          <button
            className="project-section__button"
            onClick={handlePrevPage}
            disabled={currentPage === 0}
          >
            Previous
          </button>
          <button
            className="project-section__button"
            onClick={handleNextPage}
            disabled={currentPage === totalPages - 1}
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}
